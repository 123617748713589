import Logo from '@components/Logo';
import MainNavigation from '@components/MainNavigation';
import React from 'react';
import { Link } from 'router';
import styles from './NavigationLayout.module.scss';
interface IProps {
  children: React.ReactNode;
}
const NavigationLayout: React.FC<IProps> = ({ children }: IProps) => {
  return (
    <>
      <div className={styles.headerWrap}>
        <div className={styles.headerContent}>
          <Link href="/">
            <a className={styles.logoWrap}>
              <Logo className={styles.logo} height={40} />
            </a>
          </Link>
          <MainNavigation />
        </div>
      </div>
      {children}
    </>
  );
};
export const getNavigationLayout = (page) => <NavigationLayout>{page}</NavigationLayout>;

export default NavigationLayout;
