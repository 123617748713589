import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import React from 'react';
import { useIdentity } from 'contexts/auth-context';
import { styled } from 'stitches.config';
import { useRouter } from 'next/router';
import Link from 'next/link';
import FeatherIcon from '@components/FeatherIcon';
import { HELP_PAGE, KUSTOMER_CONTACT_FORM } from '@constants/index';

const Root = styled(NavigationMenu.Root, {
  display: 'block',
  '@smMax': {
    display: 'none',
  },
});

const MobileRoot = styled(NavigationMenu.Root, {
  display: 'none',
  '@smMax': {
    display: 'block',
  },
});

const Content = styled(NavigationMenu.Content, {
  position: 'absolute',
  zIndex: '9999',
  listStyleType: 'none',
  margin: '$12',
  right: 0,
  textAlign: 'left',
  backgroundColor: '$white',
  borderRadius: '$4',
  boxShadow: '$dp4',
  backgroundClip: 'padding-box',
  border: '1px solid $grey250',
});

const SubMenuItem = styled('li', {
  padding: '$12 $16',
  color: '$grey400',
  borderRadius: '$4',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '$grey100',
    color: '$primaryPeacockDark',
    '& button': {
      color: '$primaryPeacockDark',
    },
  },
});

const List = styled(NavigationMenu.List, {
  display: 'flex',
  '&.mobile': {
    display: 'none',
  },
  '@mdMax': {
    '&.mobile': {
      display: 'flex',
    },
    '&.main': {
      display: 'none',
    },
  },
  listStyle: 'none',
  padding: 0,
  alignItems: 'center',
  whiteSpace: 'nowrap',
  gap: '$24',
});

const Trigger = styled(NavigationMenu.Trigger, {
  border: 'none',
  backgroundColor: 'transparent',
  fontSize: '$body16',
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
  cursor: 'pointer',
  fontWeight: '$semibold700',
  color: '$black',
  padding: 0,
  '&:hover': {
    color: '$primaryPeacockDark',
  },
  '&[data-state="open"]': {
    color: '$primaryPeacockDark',
  },
});

const NavigationMenuLink = styled('a', {
  display: 'flex',
  fontFamily: '$Nunito',
  alignItems: 'center',
  color: '$black',
  textAlign: 'right',
  fontWeight: '$semibold700',
  '&:hover, &:active': {
    color: '$primaryPeacockDark',
  },
  variants: {
    active: {
      true: {
        color: '$primaryPeacockDark',
      },
    },
  },
});

const MainNavigation = () => {
  const {
    state: { me },
  } = useIdentity();
  const router = useRouter();
  const isHr = !!me?.isHr;
  const isPayroll = !!me?.isPayroll;
  const settingsHref = isHr || isPayroll ? '/hr/settings' : '/settings';
  return (
    <>
      <Root>
        <List>
          {(isHr || isPayroll) && (
            <NavigationMenu.Item>
              <Link passHref href="/hr/dashboard" legacyBehavior>
                <NavigationMenuLink active={router.pathname === '/hr/dashboard'}>Dashboard</NavigationMenuLink>
              </Link>
            </NavigationMenu.Item>
          )}
          <NavigationMenu.Item>
            <Link passHref href="/" legacyBehavior>
              <NavigationMenuLink active={router.pathname === '/'}>My Leaves</NavigationMenuLink>
            </Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <Link passHref href="/files" legacyBehavior>
              <NavigationMenuLink active={router.pathname === '/files'}>Files</NavigationMenuLink>
            </Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <Trigger
              onPointerMove={(event) => event.preventDefault()}
              onPointerLeave={(event) => event.preventDefault()}
            >
              Help <FeatherIcon title="Open help menu" name="ChevronDown" aria-hidden />
            </Trigger>
            <Content
              onPointerEnter={(event) => event.preventDefault()}
              onPointerLeave={(event) => event.preventDefault()}
            >
              <SubMenuItem>
                <Link passHref href={HELP_PAGE} legacyBehavior>
                  <NavigationMenuLink target="_blank" css={{ fontWeight: '$regular400' }}>
                    Help Center
                  </NavigationMenuLink>
                </Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link passHref href={KUSTOMER_CONTACT_FORM} legacyBehavior>
                  <NavigationMenuLink target="_blank" css={{ fontWeight: '$regular400' }}>
                    Contact Us
                  </NavigationMenuLink>
                </Link>
              </SubMenuItem>
            </Content>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <Link passHref href={settingsHref} legacyBehavior>
              <NavigationMenuLink css={{ marginInlineEnd: '$8' }} active={router.pathname === settingsHref}>
                <FeatherIcon title="Account Settings" width={18} height={18} name="User" />
              </NavigationMenuLink>
            </Link>
          </NavigationMenu.Item>
        </List>
      </Root>
      <MobileRoot>
        <List>
          <NavigationMenu.Item>
            <Link passHref href={settingsHref} legacyBehavior>
              <NavigationMenuLink active={router.pathname === settingsHref}>
                <FeatherIcon title="Account Settings" width={18} height={18} name="User" />
              </NavigationMenuLink>
            </Link>
          </NavigationMenu.Item>
          <NavigationMenu.Item>
            <Trigger
              onPointerMove={(event) => event.preventDefault()}
              onPointerLeave={(event) => event.preventDefault()}
            >
              Menu <FeatherIcon title="Open menu" width={18} height={18} name="ChevronDown" />
            </Trigger>
            <Content
              onPointerEnter={(event) => event.preventDefault()}
              onPointerLeave={(event) => event.preventDefault()}
            >
              <SubMenuItem>
                {(isHr || isPayroll) && (
                  <Link passHref href="/hr/dashboard" legacyBehavior>
                    <NavigationMenuLink
                      css={{ fontWeight: '$regular400' }}
                      active={router.pathname === '/hr/dashboard'}
                    >
                      Dashboard
                    </NavigationMenuLink>
                  </Link>
                )}
              </SubMenuItem>
              <SubMenuItem>
                <Link passHref href="/" legacyBehavior>
                  <NavigationMenuLink css={{ fontWeight: '$regular400' }} active={router.pathname === '/'}>
                    My Leaves
                  </NavigationMenuLink>
                </Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link passHref href="/files" legacyBehavior>
                  <NavigationMenuLink css={{ fontWeight: '$regular400' }} active={router.pathname === '/files'}>
                    Files
                  </NavigationMenuLink>
                </Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link passHref href={HELP_PAGE} legacyBehavior>
                  <NavigationMenuLink target="_blank" css={{ fontWeight: '$regular400' }}>
                    Help Center
                  </NavigationMenuLink>
                </Link>
              </SubMenuItem>
              <SubMenuItem>
                <Link passHref href={KUSTOMER_CONTACT_FORM} legacyBehavior>
                  <NavigationMenuLink target="_blank" css={{ fontWeight: '$regular400' }}>
                    Contact Us
                  </NavigationMenuLink>
                </Link>
              </SubMenuItem>
            </Content>
          </NavigationMenu.Item>
        </List>
      </MobileRoot>
    </>
  );
};

export default MainNavigation;
