import shouldBeLoggedIn from '@utils/should-be-logged-in';
import cx from 'classnames';
import { getNavigationLayout } from 'layouts/NavigationLayout';
import React from 'react';

import styles from './DashboardLayout.module.scss';
interface IProps {
  children: React.ReactNode;
  variant?: 'small' | 'medium' | 'full';
  className?: string;
  style?: React.CSSProperties;
}

const DashboardLayout = ({ children, variant = 'medium', className, style }: IProps) => {
  return (
    <div
      className={cx(
        styles.content,
        {
          [styles[variant]]: variant,
        },
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
};

export const getSmallLayout = (page) => getNavigationLayout(<DashboardLayout variant="small">{page}</DashboardLayout>);
export const getLayout = (page) => getNavigationLayout(<DashboardLayout>{page}</DashboardLayout>);
export const getFullLayout = (page) => getNavigationLayout(<DashboardLayout variant="full">{page}</DashboardLayout>);
export const getFullContentLayout = (page) =>
  getNavigationLayout(
    <DashboardLayout variant="full" className={styles.fullContent}>
      {page}
    </DashboardLayout>,
  );

export default shouldBeLoggedIn(DashboardLayout);
